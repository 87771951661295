import { CloseSharp } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { ImageContainer } from "../../../shared/components/ImageContainer";
import { FolderBookmarkIcon } from "../../../assets/icons/icons";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../../shared/components/button/Buttons";
import { useGlobalContext } from "../../../App";
import { createComponent } from "../../../services/component";
import { toastError, toastSuccess } from "../../../utils/ToastMessage";

type Props = {
  activeDialog: any;
  handleDialog?: any;
};

const SaveAsDraftDialog: React.FC<Props> = (props: Props) => {
  const { componentDetailsList, setFormComplete } = useGlobalContext();

  const [componentSaveAsDraftLoading, setComponentSaveAsDraftLoading] =
    useState<boolean>(false);

  const handleSaveComponentAsDraft = async () => {
    setComponentSaveAsDraftLoading(true);
    for (let component of componentDetailsList) {
      try {
        const files = component["documents"];
        component["status"] = "DRAFT";
        delete component["documents"];

        await createComponent(component, files)
          .then(() => {
            setFormComplete(true);
            toastSuccess(
              "Successfully saved as draft!",
              "Product components are saved as draft."
            );
            setComponentSaveAsDraftLoading(false);
          })
          .catch((err: any) => {
            console.log(err);
            setComponentSaveAsDraftLoading(false);
            toastError(
              "Ops! Something went wrong.",
              "Could not save the components."
            );
          });
      } catch (error) {
        setComponentSaveAsDraftLoading(false);
        toastError(
          "Ops! Something went wrong.",
          "could not save the components."
        );
      }
    }
  };

  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            zIndex: 5000,
          }}
        >
          {/* Apply blur effect to only this background */}
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              border: "1px solid red",
              bgcolor: "rgba(0, 0, 0, 0.60)",
              filter: "blur(62px)",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 6000,
          }}
        >
          <Box
            sx={{
              maxWidth: "610px",
              width: "610px",
              padding: "24px",
              height: "auto",
              bgcolor: "#1B1B1B",
              borderRadius: "24px",
              border: "1px solid #3D3D3D",
              cursor: "default",
              position: "relative",
            }}
          >
            {/* Dialog content */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
              }}
            >
              {/* Title section */}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <CloseSharp
                  onClick={(e) => {
                    e.stopPropagation();
                    props?.handleDialog();
                  }}
                  sx={{
                    position: "absolute",
                    right: "24px",
                    top: "24px",
                    cursor: "pointer",
                    color: "white",
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  // gap: "24px",
                }}
              >
                <ImageContainer
                  title="folder"
                  width={"112px"}
                  height={"112px"}
                  imageContainerStyles={{
                    marginTop: "32px",
                    marginBottom: "24px",
                  }}
                >
                  {FolderBookmarkIcon}
                </ImageContainer>
                {/* -------Description container------- */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "12px",
                    textAlign: "center",
                    maxWidth: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      color: "text.secondary",
                      fontSize: "32px",
                      fontWeight: "600",
                    }}
                    // variant="h3"
                  >
                    Save your project as draft?
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(255, 255, 255, 0.5)",
                      fontSize: "18px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "-2%",
                    }}
                    // variant="subtitle1"
                  >
                    Remember to save now so you can come back to it later. If
                    you exit without saving, you will not be able to recover
                    this portfolio project.
                  </Typography>
                </Box>

                {/* -------Action buttons container------- */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    mt: "32px",
                    width: "428px",
                  }}
                >
                  <ButtonSecondary
                    sx={{
                      height: "52px",
                      width: "100%",
                      padding: "15px 16px",
                      borderRadius: "14px",
                      background: "transparent",
                    }}
                    LabelStyle={{
                      fontSize: "18px !important",
                      fontWeight: "600",
                      lineHeight: "22px",
                      color: "#FFFFFF",
                    }}
                    onClick={() => {
                      props?.handleDialog("CLOSE_WITHOUT_SAVE");
                    }}
                    label="Close without Saving"
                  />

                  <ButtonPrimary
                    sx={{
                      height: "52px",
                      width: "100%",
                      padding: "15px 16px",
                      borderRadius: "14px",
                      border: "#526600",
                    }}
                    LabelStyle={{
                      fontSize: "18px !important",
                      fontWeight: "600",
                      lineHeight: "22px",
                      color: "#000000",
                    }}
                    onClick={() => {
                      handleSaveComponentAsDraft();
                      props?.handleDialog("SAVE_AS_DRAFT");
                    }}
                    isLoading={componentSaveAsDraftLoading}
                    label="Save as draft"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  );
};

export default SaveAsDraftDialog;
