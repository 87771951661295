import React, { useState } from "react";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { useGlobalContext } from "../../../App";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../../shared/components/button/Buttons";
import TextInput from "../../../shared/components/TextInput";
import { toastError } from "../../../utils/ToastMessage";
import { getAllComponents } from "../../../services/component";

interface Props {
  selectedRows?: any;
  setSelectedRows?: any;
  allData?: any;
  rowsPerPage?: any;
  page?: any;
  toggleSelectAll?: any;
  setSelectAll?: any;
  searchFilteredProductList?: any;
  search?: any;
}

const MultipleComponentSelectDialoge: React.FC<Props> = ({
  selectedRows,
  setSelectedRows,
  allData,
  rowsPerPage,
  page,
  toggleSelectAll,
  setSelectAll,
  searchFilteredProductList,
  search,
}) => {
  const {
    multipleComponentSelectPopupOpen,
    handlemultipleComponentSelectPopupClose,
  } = useGlobalContext();
  const [selectedOption, setSelectedOption] = useState("manual");
  const [manualInput, setManualInput] = useState("100");

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleManualInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setManualInput(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (manualInput.trim() === "") {
        toastError("Error", "Input cannot be empty");
        return;
      }
      handleApplySelectMultipleComponents();
    }
  };

  const handleApplySelectMultipleComponents = () => {
    const forSelectDataComponents =
      search == "" ? allData : searchFilteredProductList;

    let newSelectedRows = [];
    if (selectedOption === "manual") {
      const manualCount = parseInt(manualInput);
      if (
        manualCount > 0 &&
        manualCount <= forSelectDataComponents?.paginatedComponents.length
      ) {
        newSelectedRows = forSelectDataComponents?.paginatedComponents
          .slice(0, manualCount)
          .map((item: any) => item.id);
      } else {
        toastError("Error", "Invalid manual input:");
        console.error("Invalid manual input");
        return;
      }
    } else if (selectedOption === "thisPage") {
      const PerPageRows = rowsPerPage || 25;
      const currentPage = page;
      newSelectedRows = forSelectDataComponents?.paginatedComponents
        .slice(currentPage * PerPageRows, (currentPage + 1) * PerPageRows)
        .map((item: any) => item.id);
    } else if (selectedOption === "all") {
      newSelectedRows = forSelectDataComponents?.paginatedComponents.map(
        (item: any) => item.id
      );
    }
    setSelectedRows(newSelectedRows);
    handlemultipleComponentSelectPopupClose();
    setSelectAll(newSelectedRows.length >= rowsPerPage);
  };

  return (
    <Box
      sx={{
        display: multipleComponentSelectPopupOpen ? "flex" : "none",
        // position: "absolute",
        // top: "67px",
        // left: "14px",
        backgroundColor: "#1B1B1B",
        border: "1px solid #292929",
        borderRadius: "14px",
        width: "448px",
        maxHeight: "306px",
        padding: "24px",
        zIndex: 1000,
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.5)",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#1B1B1B",
          zIndex: -1,
        }}
        onClick={handlemultipleComponentSelectPopupClose} // Close dialog when clicking outside
      />
      <Box textAlign="center" width="100%">
        <RadioGroup
          sx={{ display: "flex", gap: "24px" }}
          value={selectedOption}
          onChange={handleOptionChange}
        >
          <Box>
            <FormControlLabel
              value="manual"
              control={
                <Radio
                  color="primary"
                  sx={{
                    "&.MuiRadio-root": {
                      color: " #CF0",
                    },
                  }}
                />
              }
              label={
                <Typography
                  variant="body1"
                  sx={{ color: "#FFFFFF", fontSize: "18px", fontWeight: "600" }}
                >
                  Select number of components
                </Typography>
              }
              sx={{ height: "24px", width: "100%" }}
            />
            {selectedOption === "manual" && (
              <TextInput
                inputStyles={{
                  borderRadius: "14px",
                  backgroundColor: "#282828",
                  color: "#FFFFFF",
                  fontSize: "18px",
                  fontWeight: "600",
                  padding: "14px",
                  height: "50px",
                  marginTop: "18px",
                }}
                onChange={handleManualInputChange}
                value={manualInput}
                onKeyDown={handleKeyDown}
              />
            )}
          </Box>
          <FormControlLabel
            value="thisPage"
            control={
              <Radio
                color="primary"
                sx={{
                  "&.MuiRadio-root": {
                    color: " #CF0",
                  },
                }}
              />
            }
            label={
              <Typography
                variant="body1"
                sx={{ color: "#FFFFFF", fontSize: "18px", fontWeight: "600" }}
              >
                Select this page
                <span
                  style={{
                    width: "39px",
                    height: "23px",
                    padding: "3px 11px",
                    backgroundColor: "#282828",
                    color: "#FFFFFF",
                    borderRadius: "60px",
                    marginLeft: "12px",
                    fontSize: "14px",
                  }}
                >
                  {rowsPerPage || 25}
                </span>
              </Typography>
            }
            sx={{ height: "24px" }}
          />
          <FormControlLabel
            value="all"
            control={
              <Radio
                color="primary"
                sx={{
                  "&.MuiRadio-root": {
                    color: " #CF0",
                  },
                }}
              />
            }
            label={
              <Typography
                variant="body1"
                sx={{ color: "#FFFFFF", fontSize: "18px", fontWeight: "600" }}
              >
                Select all
                <span
                  style={{
                    width: "49px",
                    height: "23px",
                    padding: "3px 11px",
                    backgroundColor: "#282828",
                    color: "#FFFFFF",
                    borderRadius: "60px",
                    marginLeft: "12px",
                    fontSize: "14px",
                  }}
                >
                  {allData?.paginatedComponents.length || 400}
                </span>
              </Typography>
            }
            sx={{ height: "24px" }}
          />
        </RadioGroup>
        {/* Action Buttons  */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            mt: "24px",
          }}
        >
          <ButtonSecondary
            sx={{
              width: "116px !important",
              height: "46px !important",
              bgcolor: "transparent",
              border: "1px solid #3D3D3D",
              padding: "15px 16px",
              borderRadius: "14px",
            }}
            onClick={() => {
              handlemultipleComponentSelectPopupClose();
            }}
            LabelStyle={{
              fontSize: "18px !important",
              fontWeight: "600",
              lineHeight: "22px",
            }}
            label="Cancel"
          />
          <ButtonPrimary
            sx={{
              height: "46px !important",
              width: "112px",
              padding: "15px 32px",
              borderRadius: "14px",
            }}
            onClick={() => {
              handleApplySelectMultipleComponents();
            }}
            LabelStyle={{
              fontSize: "18px !important",
              fontWeight: "600",
              lineHeight: "22px",
            }}
            label="Apply"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MultipleComponentSelectDialoge;
