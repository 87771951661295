import React from "react";
import { IconColor } from "../interface/ColorInterface";

const LicenseIconSvg : React.FC<IconColor>= ({
  color,
  width,
  height,
  transform,
}) => {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6096_12243)">
        <path
          d="M7.56912 11.2413L8.21635 11.6112L8.62986 10.9909L12.509 5.17222L17.0618 11.026L17.5175 11.6119L18.1351 11.2002L21.7256 8.80706L20.458 14.2997H4.55052L3.27892 8.78945L7.56912 11.2413ZM12.4404 5.08409C12.4404 5.08404 12.4404 5.084 12.4403 5.08395L12.4404 5.08409ZM21.803 8.69556C21.8029 8.69545 21.8027 8.69534 21.8025 8.69523L21.847 8.63183L21.8903 8.69725C21.8665 8.71305 21.8322 8.71517 21.803 8.69556Z"
          stroke={color || "white"}
          stroke-width="1.59722"
        />
        <path
          d="M20.762 16.5352L20.3933 18.1344C20.3179 18.4607 20.0275 18.6914 19.6925 18.6914H5.31755C4.98261 18.6914 4.69223 18.4607 4.61677 18.1344L4.24805 16.5352H20.762Z"
          fill={color || "white"}
        />
      </g>
      <defs>
        <clipPath id="clip0_6096_12243">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LicenseIconSvg;
