import * as React from "react";
import { useState } from "react";
import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";

interface FilterItem {
  label: string;
  selected: boolean;
}

interface FilterData {
  licenses: FilterItem[];
}

const filterData: FilterData = {
  licenses: [
    { label: "Premium", selected: true },
    { label: "Free", selected: true },
  ],
};

const LicenseFiltersComponent: React.FC = () => {
  const [isTitleSelected, setIsTitleSelected] = useState<boolean>(true);

  const [selectedFilterData, setSelectedFilterData] = useState<FilterData>({
    ...filterData,
  });

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedFilterData = {
      ...selectedFilterData,
      licenses: selectedFilterData.licenses.map((item, i) =>
        i === index ? { ...item, selected: event.target.checked } : item
      ),
    };
    setSelectedFilterData(updatedFilterData);

    // Check if all licenses are selected to update Title checkbox
    const allSelected = updatedFilterData.licenses.every(
      (item) => item.selected
    );
    setIsTitleSelected(allSelected);
  };

  return (
    <>
      <Box
        sx={{
          borderBottom: "1px solid #282828",
          minHeight: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 24px",
          mb: "12px",
          pt: "16px",
          pb: "16px",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={isTitleSelected}
              onChange={(event) => {
                const isChecked = event.target.checked;
                setIsTitleSelected(isChecked);

                const updatedFilterData = {
                  ...selectedFilterData,
                  licenses: selectedFilterData.licenses.map((item) => ({
                    ...item,
                    selected: isChecked,
                  })),
                };
                setSelectedFilterData(updatedFilterData);
              }}
              sx={{
                width: "24px",
                height: "24px",
                borderRadius: "6px",
                padding: "4px",
                border: isTitleSelected ? "" : "1px solid #3D3D3D",
                "&.MuiCheckbox-root": {
                  color: "#1b1b1b",
                },
                "&.Mui-checked": {
                  color: "#CF0",
                  borderRadius: "6px",
                  '& .MuiSvgIcon-root': { fontSize: 28 }
                },
              }}
            />
          }
          label="Title"
          labelPlacement="start"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            margin: 0,
            padding: 0,
            fontSize: "16px",
            fontWeight: 500,
            color: isTitleSelected ? "#FFFFFF" : "#8D8D8D",
          }}
        />
      </Box>
      <Box sx={{ maxHeight: "100vh", marginX: "24px" }}>
        <FormGroup
          sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
        >
          {selectedFilterData.licenses.map((item, index) => (
            <>
              <Box
                key={index}
                sx={{
                  // borderBottom: "1px solid rgba(255, 255, 255, 0.06)",
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={item.selected}
                      onChange={(event) => handleCheckboxChange(event, index)}
                      sx={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "6px",
                        padding: "4px",
                        // backgroundColor: item.selected ? "black" : "transparent",
                        border: item.selected ? "" : "1px solid #3D3D3D",
                        "&.MuiCheckbox-root": {
                          color: "#1b1b1b",
                        },
                        "&.Mui-checked": {
                          color: "#CF0",
                        //   backgroundColor: "#CF0",
                          borderRadius: "6px",
                          '& .MuiSvgIcon-root': { fontSize: 28 }
                        },
                      }}
                    />
                  }
                  label={item.label}
                  labelPlacement="start"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    margin: 0,
                    padding: 0,
                    fontSize: "16px",
                    lineHeight: "22px",
                    fontWeight: 400,
                    color: "#FFFFFF",
                  }}
                />
              </Box>
              {index < selectedFilterData.licenses.length - 1 ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "1px",
                    bgcolor: "rgba(255, 255, 255, 0.06)",
                  }}
                ></Box>
              ) : null}
            </>
          ))}
        </FormGroup>
      </Box>
    </>
  );
};

export default LicenseFiltersComponent;
