import * as React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import Search from "../../../shared/components/Search";
import { getAllComponentsCollections } from "../../../services/component";

interface FilterItem {
  label: string;
  selected: boolean;
  logoUrl: string;
}

interface FilterData {
  collections: FilterItem[];
}

// const filterData: FilterData = {
//   collections: [
//     { label: "Skyline", selected: true },
//     { label: "Pixel Boom", selected: false },
//     { label: "Sparkly", selected: true },
//     { label: "Winzy", selected: true },
//   ],
// };

const CollectionFiltersComponent: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState("");
  const [isTitleSelected, setIsTitleSelected] = useState<boolean>(true);
  // const [selectedFilterData, setSelectedFilterData] = useState<FilterData>({
  //   ...filterData,
  // });
  const [selectedFilterData, setSelectedFilterData] = useState<FilterData>({
    collections: [],
  });

  const [filteredCollections, setFilteredCollections] = useState<FilterItem[]>(
    []
  );

  // Fetch collections from API
  const fetchCollections = async (filters?: any) => {
    setLoading(true);
    try {
      const res = await getAllComponentsCollections();
      const collections = res.paginatedComponentCollections.map(
        (collection: any) => ({
          label: collection.name,
          selected: true,
          logoUrl: collection.logoUrl,
        })
      );
      setSelectedFilterData({ collections });
      setFilteredCollections(collections);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCollections();
  }, []);

  useEffect(() => {
    // Filter collections based on search input
    const filtered = selectedFilterData.collections.filter((item) =>
      item.label.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredCollections(filtered);
  }, [search, selectedFilterData.collections]);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedFilterData = {
      ...selectedFilterData,
      collections: selectedFilterData.collections.map((item, i) =>
        i === index ? { ...item, selected: event.target.checked } : item
      ),
    };
    setSelectedFilterData(updatedFilterData);

    // Check if all collections are selected to update Title checkbox
    const allSelected = updatedFilterData.collections.every(
      (item) => item.selected
    );
    setIsTitleSelected(allSelected);
  };

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", height: "52px", m: "24px 20px 12px 20px" }}>
          <Search
            searchFormStyles={{
              minHeight: "22px",
            }}
            style={{
              minHeight: "52px",
              color: "white",
              backgroundColor: "#292929",
              paddingLeft: "47px",
            }}
            icon={true}
            iconStyles={{
              position: "absolute",
              left: "15px",
            }}
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Box>
        <Box
          sx={{
            borderBottom: "1px solid #282828",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px 24px",
            mb: "12px",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={isTitleSelected}
                onChange={(event) => {
                  const isChecked = event.target.checked;
                  setIsTitleSelected(isChecked);

                  const updatedFilterData = {
                    ...selectedFilterData,
                    collections: selectedFilterData.collections.map((item) => ({
                      ...item,
                      selected: isChecked,
                    })),
                  };
                  setSelectedFilterData(updatedFilterData);
                }}
                sx={{
                  width: "24px",
                  height: "24px",
                  borderRadius: "6px",
                  padding: "4px",
                  border: isTitleSelected ? "" : "1px solid #3D3D3D",
                  "&.MuiCheckbox-root": {
                    color: "#1b1b1b",
                  },
                  "&.Mui-checked": {
                    color: "#CF0",
                    borderRadius: "6px",
                    '& .MuiSvgIcon-root': { fontSize: 28 }
                  },
                }}
              />
            }
            label="Title"
            labelPlacement="start"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              margin: 0,
              padding: 0,
              fontSize: "16px",
              fontWeight: 500,
              color: isTitleSelected ? "#FFFFFF" : "#8D8D8D",
            }}
          />
        </Box>
      </Box>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75%",
          }}
        >
          <CircularProgress
            size={24}
            sx={{
              color: "white",
            }}
          />
        </Box>
      ) : (
        <Box sx={{ maxHeight: "100vh", marginX: "24px" }}>
          <FormGroup
            sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
          >
            {/* {selectedFilterData.collections.map((item, index) => ( */}
            {filteredCollections.map((item, index) => (
              <>
                <Box
                  key={index}
                  sx={{
                    // borderBottom: "1px solid rgba(255, 255, 255, 0.06)",
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={item.selected}
                        onChange={(event) => handleCheckboxChange(event, index)}
                        sx={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "6px",
                          padding: "4px",
                          border: item.selected ? "" : "1px solid #3D3D3D",
                          "&.MuiCheckbox-root": {
                            color: "#1b1b1b",
                          },
                          "&.Mui-checked": {
                            color: "#CF0",
                            borderRadius: "6px",
                            '& .MuiSvgIcon-root': { fontSize: 28 }
                          },
                        }}
                      />
                    }
                    // label={item.label}
                    label={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={item.logoUrl}
                          alt={item.label}
                          style={{
                            width: "18px",
                            height: "18px",
                            marginRight: "8px",
                          }}
                        />
                        {item.label}
                      </Box>
                    }
                    labelPlacement="start"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      margin: 0,
                      padding: 0,
                      fontSize: "16px",
                      lineHeight: "22px",
                      fontWeight: 400,
                      color: "#FFFFFF",
                    }}
                  />
                </Box>
                {/* {index < selectedFilterData.collections.length - 1 ? ( */}
                {index < filteredCollections.length - 1 ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "1px",
                      bgcolor: "rgba(255, 255, 255, 0.06)",
                    }}
                  ></Box>
                ) : null}
              </>
            ))}
          </FormGroup>
        </Box>
      )}
    </>
  );
};

export default CollectionFiltersComponent;
