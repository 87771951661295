import { AlertTriangleIconSvg } from "../../assets/icons/AlertTriangleIconSvg";
import CategoryIconSvg from "../../assets/icons/categoryIcon/CategoryIconSvg";
import { CollectionsIconSvg } from "../../assets/icons/Collections/CollectionsIconSvg";
import { DashboardIconSvg } from "../../assets/icons/DashboardIconSvg";
import { LayersIconSvg } from "../../assets/icons/LayersIconSvg";
import LicenseIconSvg from "../../assets/icons/licenseIcon/LicenseIconSvg";
import { MessageIconSvg } from "../../assets/icons/MessageIconSvg";
import { SettingIconSvg } from "../../assets/icons/SettingIcon/SettingIconSvg";

export const mainNavigation: any = [
  {
    text: "Dashboard",
    module: "DASHBOARD",
    icon: <DashboardIconSvg color="rgba(255, 255, 255, 0.5)" />,
    iconSelected: <DashboardIconSvg color="white" />,
    to: "/",
  },
  {
    text: "Components",
    module: "PRODUCTS",
    icon: <CollectionsIconSvg color="rgba(255, 255, 255, 0.5)" />,
    iconSelected: <CollectionsIconSvg color="white" />,
    to: "/product",
  },
  {
    text: "Collections",
    module: "COLLECTIONS",
    icon: <LayersIconSvg color="rgba(255, 255, 255, 0.5)" />,
    iconSelected: <LayersIconSvg color="white" />,
    to: "/collections",
  },
  {
    text: "Reports",
    module: "REPORTS",
    icon: <AlertTriangleIconSvg color="rgba(255, 255, 255, 0.5)" />,
    iconSelected: <AlertTriangleIconSvg color="white" />,
    to: "/reports",
  },
  {
    text: "Feedbacks",
    module: "FEEDBACKS",
    icon: <MessageIconSvg color="rgba(255, 255, 255, 0.5)" />,
    iconSelected: <MessageIconSvg color="white" />,
    to: "/feedbacks",
  },
  {
    text: "Settings",
    module: "SETTING",
    icon: <SettingIconSvg color="rgba(255, 255, 255, 0.5)" />,
    iconSelected: <SettingIconSvg color="white" />,
    to: "/settings",
  },
];

export const FiltersPopupNavigation: any = [
  {
    text: "Categories",
    module: "CATEGORIES",
    icon: <CategoryIconSvg color="rgba(255, 255, 255, 0.5)" />,
    iconSelected: <CategoryIconSvg color="white" />,
  },
  {
    text: "Collections",
    module: "COLLECTIONS",
    icon: <CollectionsIconSvg color="rgba(255, 255, 255, 0.5)" />,
    iconSelected: <CollectionsIconSvg color="white" />,
  },
  {
    text: "License",
    module: "LICENSES",
    icon: <LicenseIconSvg color="rgba(255, 255, 255, 0.5)" />,
    iconSelected: <LicenseIconSvg color="white" />,
  },
];
