import { Box, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import TextInput from "../../shared/components/TextInput";
import { createStyles, makeStyles } from "@mui/styles";
import { ImageIconSvg } from "../../assets/icons/ImageIconSvg";
import { useGlobalContext } from "../../App";
import Dropzone, { DropzoneRef } from "react-dropzone";
import { CrossIconSvg } from "../../assets/icons/CrossIconSvg";
import Dropdown from "../../shared/components/Dropdown";
import { toastError, toastSuccess } from "../../utils/ToastMessage";
import {
  createComponentCollection,
  updateComponentCollection,
  uploadDocument,
} from "../../services/component";
import { useLocation, useParams } from "react-router-dom";
import CollectionComponentsList from "./CollectionComponents/CollectionComponentsList";

type Props = {
  handleRefreshData?: any;
};

const CollectionDetailScreen = (props: Props) => {
  const dropZoneRef = useRef<DropzoneRef | null>(null);
  const styles = useStyles();
  const { setUpdateCollectionFunction } = useGlobalContext();
  const location = useLocation();
  const { collectionData } = location.state || {}; // Took id from location state
  const { id } = useParams();
  const CollectionComponentObject: any = {
    name: collectionData?.name || "",
    logoUrl: collectionData?.logoUrl || "",
    status: collectionData?.status || "",
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [collection, setCollection] = useState<any>(CollectionComponentObject);
  const [logo, setLogo] = useState<any>(collectionData?.logoUrl || "");

  const handleChangeName = (name: string) => {
    setCollection((prev: any) => ({ ...prev, name }));
  };

  const handleChangeStatus = (status: string) => {
    setCollection((prev: any) => ({ ...prev, status }));
  };

  const handleChangeThumbnail = (files: File[]) => {
    setLogo(files[0]);
  };

  const handleUpdateCollection = async () => {
    setLoading(true);

    //If logo has a type It means that user wants to update the logo
    if (logo?.type) {
      let logoUrl: string = "";
      //Upload the logo first then set the Url
      await uploadDocument(logo).then((res: any) => {
        logoUrl = res?.data[0]?.url;
        if (logoUrl) {
          collection.logoUrl = logoUrl;
        }
      });
    }

    await updateComponentCollection(id, collection)
      .then((res) => {
        // console.log("res", res);
        toastSuccess("Success", "Collection updated successfully!");
      })
      .catch((err: any) => {
        console.log("err", err);
        toastError("Error", "Failed to update collection.");
      });

    // props?.handleRefreshData();

    setLoading(false);
  };

  useEffect(() => {
    setUpdateCollectionFunction(() => handleUpdateCollection);
  }, [setUpdateCollectionFunction, collection]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Box
        sx={{
          width: "85%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingTop: "32px",
          gap: "24px",
        }}
      >
        <Typography
          sx={{
            width: "100%",
            color: "#FFFFFF",
            fontSize: "24px",
            fontWeight: "600",
            lineHeight: "28px",
          }}
        >
          Information
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // maxWidth: "980px",
            // minWidth: "980px",
            width: "100%",
            height: "282px",
            bgcolor: "#141414",
            borderRadius: "14px",
            padding: "40px",
          }}
        >
          {/* Row1 */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "40px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                gap: "24px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  flex: 1,
                }}
              >
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  Logo
                </Typography>

                <Box
                  sx={{
                    flex: 1,
                    borderRadius: "14px",
                    border: "2px dashed #3D3D3D",
                    bgcolor: "rgba(255, 255, 255, 0.06)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "168px",
                    height: "168px",
                    position: "relative",
                  }}
                >
                  {logo ? (
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        backgroundImage:
                          logo instanceof File
                            ? `url(${URL.createObjectURL(logo)})`
                            : `url(${logo})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "14px",
                        position: "relative",
                        "&:hover .overlay": {
                          opacity: 1,
                        },
                      }}
                    >
                      <Box
                        className="overlay"
                        sx={{
                          position: "absolute",
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: "rgba(0, 0, 0, 0.8)",
                          opacity: 0,
                          transition: "opacity 0.3s ease",
                          borderRadius: "14px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "12px",
                            gap: "9px",
                            position: "absolute",
                            right: "0px",
                            top: "0px",
                          }}
                        >
                          <Box
                            onClick={() => setLogo("")}
                            sx={{
                              width: "24px",
                              height: "24px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "75px",
                              backgroundColor: "#E25454",
                              cursor: "pointer",
                            }}
                          >
                            <CrossIconSvg color="white" />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Dropzone
                      accept={{
                        "image/png": [".png"],
                        "image/jpeg": [".jpeg", ".jpg"],
                      }}
                      ref={dropZoneRef}
                      disabled={false}
                      multiple={true}
                      onDrop={(acceptedFiles) =>
                        handleChangeThumbnail(acceptedFiles)
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flex: 1,
                          }}
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "4px",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <ImageIconSvg />
                            <Typography
                              sx={{ color: "text.secondary", mt: "10px" }}
                              variant="subtitle1"
                            >
                              Choose Files
                            </Typography>
                            <Typography
                              sx={{
                                maxWidth: "70%",
                                textAlign: "center",
                              }}
                              color={"rgba(255, 255, 255, 0.5)"}
                              variant="body2"
                            >
                              24x24px size required in PNG
                            </Typography>
                          </Box>
                        </div>
                      )}
                    </Dropzone>
                  )}
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                width: "100%",
              }}
            >
              <Box sx={{ flex: 1 }} className={styles.inputContainer}>
                <TextInput
                  style={{ flex: 1 }}
                  label="Title"
                  lableStyles={{ fontSize: "16px !important" }}
                  placeholder="Enter the title"
                  type="text"
                  value={collection?.name}
                  onChange={(e: any) => handleChangeName(e.target.value)}
                />

                <Typography
                  className="err_field"
                  id="createProductTitlebNotExist"
                  color="red"
                  variant="body2"
                ></Typography>
              </Box>

              <Box>
                <Dropdown
                  label="Status"
                  className={styles.dropdown}
                  classDropdownLabel={styles.label}
                  classDropdownPlaceholder={styles.placeholder}
                  classDropdownValueText={styles.dropdownValueText}
                  dropdownOptionsBodyStyles={{
                    fontWeight: "300",
                    fontSize: "16px",
                  }}
                  dropdownBodyStyles={{
                    width: "100%",
                  }}
                  value={collection.status}
                  placeholder="Select"
                  // setValue={handleChangeStatus}
                  setValue={(value: any) => {
                    // unselect logic
                    if (value === collection.status) {
                      handleChangeStatus(""); 
                      return;
                    }
                    handleChangeStatus(value);
                  }}
                  options={["ACTIVE", "INACTIVE", "DRAFT"]}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* -----------All collection Components-------  */}
      <>
       <CollectionComponentsList/> 
      </>
    </Box>
  );
};

export default CollectionDetailScreen;

const useStyles = makeStyles((theme) =>
  createStyles({
    dropdown: {
      width: "100%",
      minWidth: "200px",
    },
    inputContainer: {
      flex: 1,
    },
    placeholder: {
      color: "#A0AEC0",
      fontSize: "16px",
      fontWeight: "300 !important",
      fontFamily: "Outfit",
    },
    dropdownValueText: {
      color: "white",
      fontSize: "16px",
      fontWeight: "300",
    },
    label: {
      color: "#FFFFFF",
    },
  })
);
