import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LayoutTable from "./layout/Table";
import { useGlobalContext } from "../../App";
import { getAllComponentsCollections } from "../../services/component";
import AddCollection from "./components/AddCollection";

type Props = {};

export interface ActionMenuProps {
  title: string;
  action: () => void;
  actionTitle: string;
}

const tableRef = React.createRef<any>();

const CollectionsList = (props: Props) => {
  const { addCollectionUI } = useGlobalContext();
  const columnItems = [
    { id: "Icon", label: "Icon" },
    { id: "Name", label: "Name" },
    { id: "No. of Components", label: "No. of Components" },
    { id: "Status", label: "Status" },
  ];

  const [loading, setLoading] = useState(true);
  const [collectionsList, setCollectionsList] = useState<any>([]);

  const fetchCollections = async (filters?: any) => {
    setLoading(true);
    await getAllComponentsCollections(filters)
      .then((res: any) => {
        setLoading(false);
        setCollectionsList(res);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCollections({ includeComponents: true });
  }, []);

  const handleRefreshData = () => {
    fetchCollections();
  };
  useEffect(() => {}, [collectionsList]);

  return (
    <>
      {!addCollectionUI ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            paddingTop: "32px",
            paddingBottom: "30px",
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                right: 0,
                left: 0,
                bottom: 0,
                top: 120,
                height: "100%",
              }}
            >
              <CircularProgress
                size={24}
                sx={{
                  color: "white",
                  marginRight: "12px",
                }}
              />
            </Box>
          ) : collectionsList?.paginatedComponentCollections?.length > 0 ? (
            <>
              <Box
                sx={{
                  width: "100%",
                  // overflowY: "scroll",
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      width: "85%",
                    }}
                  >
                    <LayoutTable
                      loading={loading}
                      rows={collectionsList?.paginatedComponentCollections}
                      columns={columnItems}
                      handleRefreshData={handleRefreshData}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "60vh",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: "white" }} variant="h5">
                No Collections to display
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        // ------------Add collections Form-----------
        <>
          <Box>
            <AddCollection handleRefreshData={handleRefreshData} />
          </Box>
        </>
      )}
    </>
  );
};

export default CollectionsList;
