import { Box, Button, TableCell, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";
import { ImageContainer } from "../../../../shared/components/ImageContainer";
import { TrashIcon } from "../../../../assets/icons/icons";
import { useNavigate } from "react-router-dom";
import DeleteDialog from "../../../products/product/DeleteDialog";
import {
  deleteComponent,
  updateComponent,
} from "../../../../services/component";
import { toastError, toastSuccess } from "../../../../utils/ToastMessage";
import { statusType } from "../../../../utils/constants";
import {
  capitalizeFirstLetter,
  monthStringFormatDate,
} from "../../../../utils/extensions";

interface tableRowProps {
  row: any;
  handleRefreshData: any;
  isSelected: any;
  onSelect: (id: any, checked: boolean) => any;
  onDelete: (id: any) => any;
}

const Row: React.FC<tableRowProps> = ({
  row,
  handleRefreshData,
  isSelected,
  onSelect,
  onDelete,
}) => {
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const navigate = useNavigate();

  const handleDelete = () => {
    const data = { collectionName: "", collectionId: null };

    updateComponent(row?.id, data, [])
      .then((res: any) => {
        toastSuccess(
          "Successfully Deleted!",
          "Component deleted successfully."
        );
        handleRefreshData();
        onDelete(row?.id);
      })
      .catch((err) => console.log("err", err));
  };
  return (
    <TableRow
      onClick={() => {}}
      sx={{
        cursor: "pointer",
        ":hover": {
          bgcolor: "rgba(255, 255, 255, 0.06) !important",
        },
      }}
      hover
      role="checkbox"
      tabIndex={-1}
      key={row?.id}
    >
      <TableCell
        sx={{
          width: "calc(100% / 5)",
          borderBottom: "1px solid rgba(255, 255, 255, 0.06)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          {row?.documents?.length > 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "3px",
              }}
            >
              <img
                style={{
                  width: "104px",
                  height: "72px",
                  borderRadius: "3px",
                }}
                alt="cover"
                src={row?.documents[0]?.url}
              />
            </Box>
          )}
          <Typography
            sx={{ color: "white", fontWeight: "300" }}
            variant="body2"
          >
            {row?.title || "-"}
          </Typography>
        </Box>
      </TableCell>

      <TableCell
        sx={{
          width: "calc(100% / 5)",
          borderBottom: "1px solid rgba(255, 255, 255, 0.06)",
        }}
      >
        <Box
          sx={{ display: "flex", gap: "10px", width: "100%", flexWrap: "wrap" }}
        >
          {row?.tags?.map((tag: string, index: number) => {
            return (
              <Typography
                key={index}
                sx={{ color: "white", fontWeight: "300" }}
                variant="body2"
              >
                {capitalizeFirstLetter(tag)},
              </Typography>
            );
          })}
        </Box>
      </TableCell>

      <TableCell
        sx={{
          width: "calc(100% / 5)",
          borderBottom: "1px solid rgba(255, 255, 255, 0.06)",
        }}
      >
        <Typography sx={{ color: "white", fontWeight: "300" }} variant="body2">
          {row?.status ? statusType(row?.status) : "-"}
        </Typography>
      </TableCell>

      <TableCell
        sx={{
          width: "calc(100% / 5)",
          borderBottom: "1px solid rgba(255, 255, 255, 0.06)",
        }}
      >
        <Typography sx={{ color: "white", fontWeight: "300" }} variant="body2">
          {row?.createdAt ? monthStringFormatDate(row?.createdAt) : "-"}
        </Typography>
      </TableCell>

      <TableCell
        sx={{
          width: "calc(100% / 5)",
          borderBottom: "1px solid rgba(255, 255, 255, 0.06)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              setIsDeleteDialogVisible(true);
            }}
            sx={{
              minWidth: "0px",
              minHeight: "0px",
              lineHeight: "0px",
            }}
          >
            <ImageContainer
              style={{ width: "24px", height: "24px" }}
              title="trash"
            >
              {TrashIcon}
            </ImageContainer>
          </Button>
        </Box>

        {isDeleteDialogVisible && (
          <DeleteDialog
            title="Are you sure you want to remove the component from collection?"
            setIsDeleteDialogVisible={setIsDeleteDialogVisible}
            deleteAction={() => {
              handleDelete();
            }}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;
