import React, { ReactElement, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, CircularProgress, Typography } from "@mui/material";
import Row from "./Row";
import { SECTION_MIN_HEIGHT } from "../../../../utils/constants";
import { SortDownSvg } from "../../../../assets/icons/SortDownSvg";
import { SortUpSvg } from "../../../../assets/icons/SortUpSvg";
import { useGlobalContext } from "../../../../App";
import CheckboxInput from "../../../../shared/components/CheckboxInput";
import MultipleComponentSelectDialoge from "../../FiltersComponent/MultipleComponentSelectDialoge";

interface ColumnGroupingTableProps {
  columns: any;
  rows: any;
  head?: ReactElement;
  loading?: boolean;
  handleRefreshData?: any;
  selectedRows?: any;
  setSelectedRows?: any;
  handleSelectedMenu?: (props: any) => void;
  activeSelectedMenu: boolean;
  allData?: any;
  rowsPerPage?: number;
  page?:number
  searchFilteredProductList?: any;
  search?: any;
}

const LayoutTable: React.FC<ColumnGroupingTableProps> = ({
  columns,
  rows,
  head,
  loading,
  handleRefreshData,
  selectedRows,
  setSelectedRows,
  handleSelectedMenu,
  activeSelectedMenu,
  allData,
  rowsPerPage,
  page,
  searchFilteredProductList,
  search,
}) => {
  const {
    setShowSelectedRowsMenuFooter,
    multipleComponentSelectPopupOpen,
    handlemultipleComponentSelectPopupOpen,
  } = useGlobalContext();
  const [columnsSortActions, setColumnSortActions] = useState<any>({
    Employee: "",
    BusinessUnit: "",
    Position: "",
    Team: "",
    HireDate: "",
    Status: "",
    DateEdited: "", // Add this line
  });

  const [sortBy, setSortBy] = useState<string>("");

  const [selectAll, setSelectAll] = useState(false);

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(rows?.map((row: any) => row.id));
    }
    setSelectAll((prev) => !prev);
  };

  const handleChangeSortBy = (sortVal: string) => {
    setSortBy(sortVal);
    if (sortVal) {
      setColumnSortActions((prevActions: any) => {
        const updatedActions = Object.fromEntries(
          Object.keys(prevActions).map((key) => [
            key,
            key === sortVal
              ? prevActions[key] === "Asc"
                ? "Desc"
                : "Asc"
              : "",
          ])
        );
        return { ...prevActions, ...updatedActions };
      });
    }
  };

  useEffect(() => {}, [sortBy]);

  useEffect(() => {}, [columnsSortActions]);

  useEffect(() => {
    if (selectedRows?.length > 0) {
      if (selectedRows?.length < rows?.length) {
        setSelectAll(false);
      }
      setShowSelectedRowsMenuFooter(true);
      handleSelectedMenu &&
        handleSelectedMenu({
          title: `${selectedRows?.length} Selected Rows`,
          action: () => {},

          actionTitle: "Delete",
        });
    } else {
      setSelectAll(false);
      setShowSelectedRowsMenuFooter(false);
    }
    // eslint-disable-next-line
  }, [selectedRows]);

  // useEffect(() => {
  //   if (selectAll) {
  //     setSelectedRows((prev: any) => [
  //       ...prev,
  //       ...rows
  //         ?.map((row: any) => row.id)
  //         .filter((id: any) => !prev.includes(id)), // Only include IDs that aren't already in the state
  //     ]);
  //   }
  //   // eslint-disable-next-line
  // }, [rows]);

  return (
    <Paper
      sx={{
        p: "40px",
        borderRadius: "16px",
        backgroundColor: "#000000",
        "&.MuiPaper-root": {
          boxShadow: "none",
          padding: "0px",
          backgroundColor: "#000000",
          backgroundImage: "none",
        },
      }}
    >
      <Box sx={{ position: "relative" }}>
        <TableContainer
          className="HideScrollbar"
          // ref={scrollRef}
          sx={{
            overflowY: "scroll",
            overflowX: "scroll",
            bgcolor: "#141414 !important",
            borderRadius: "16px",
            // maxHeight: `calc(${SECTION_MIN_HEIGHT} - ${"0px"})`,
            // minHeight: `calc(${SECTION_MIN_HEIGHT} - ${"0px"})`,
            height: "75vh",
            scrollbarWidth: "none",
          }}
        >
          {multipleComponentSelectPopupOpen && (
            <Box
              sx={{
                position: "absolute",
                top: "67px",
                left: "14px",
                zIndex: 1000,
              }}
            >
              <MultipleComponentSelectDialoge
                allData={allData}
                rowsPerPage={rowsPerPage}
                page={page}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                // toggleSelectAll={toggleSelectAll}
                setSelectAll={setSelectAll}
                searchFilteredProductList={searchFilteredProductList}
                search={search}
              />
            </Box>
          )}
          <Box
            sx={{
              paddingRight: "24px",
            }}
          >
            {head}
          </Box>
          <Table
            stickyHeader
            sx={{
              padding: "22px 40px",
              paddingRight: "40px",
              "&.MuiTable-root": {
                paddingRight: "40px",
              },
              position: "relative",
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  borderRadius: "10px",
                  width: "fit-content",
                  borderBottom:
                    "1px solid rgba(255, 255, 255, 0.06) !important",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  borderBottomRightRadius: "10px",
                  boxShadow: "none !important",
                  "&.MuiTableRow-root": {
                    backgroundColor: "#141414",
                  },
                }}
              >
                {columns?.map((column: any, index: number) => {
                  return (
                    <TableCell
                      sx={{
                        bgcolor: "#141414 !important",
                        color: "rgba(255, 255, 255, 0.5)",
                        "&.MuiTableCell-root": {
                          borderBottom: "1px solid rgba(255, 255, 255, 0.06)",
                        },
                      }}
                      key={column?.id}
                      style={{
                        width: "fit-content",
                        minWidth: index === 0 ? "240px" : "80px",
                        padding: "0px",
                        paddingLeft: "16px",
                        backgroundColor: "transparent",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          height: "100% !important",
                          alignItems: "center",
                          gap: "16px",
                        }}
                      >
                        {index === 0 ? (
                          <CheckboxInput
                            style={{ marginRight: "12px" }}
                            onChange={() => {
                              // toggleSelectAll();
                              if (selectAll) {
                                setSelectAll(false);
                                setSelectedRows([]); 
                              } else {
                                handlemultipleComponentSelectPopupOpen();
                              }
                              // handlemultipleComponentSelectPopupOpen(); 
                            }}
                            value={selectAll}
                          />
                        ) : (
                          <></>
                        )}
                        <Box
                          onClick={() => {
                            handleChangeSortBy(
                              column?.label?.replace(/\s+/g, "")
                            );
                          }}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            gap: "10px",
                            height: "100% !important",
                            cursor: index == 6 ? "pointer" : "default",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              width: "fit-content",
                              fontWeight: "500",
                              height: "100% !important",
                            }}
                          >
                            {" "}
                            {column.label}
                          </Typography>
                          {index !== 0 && index !== 1 && index !== 2 ? (
                            columnsSortActions[
                              column.label?.replace(/\s+/g, "")
                            ] === "Asc" ? (
                              <SortDownSvg />
                            ) : columnsSortActions[
                                column?.label?.replace(/\s+/g, "")
                              ] === "Desc" ? (
                              <SortUpSvg />
                            ) : (
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.46055 2.32179C6.75848 2.02815 7.24152 2.02815 7.53945 2.32179L10.275 5.01797C10.7556 5.49165 10.4152 6.30156 9.73558 6.30156H4.26442C3.58476 6.30156 3.24438 5.49165 3.72498 5.01797L6.46055 2.32179Z"
                                  fill="#CBD5E0"
                                />
                                <path
                                  d="M6.46055 11.6813C6.75848 11.975 7.24152 11.975 7.53945 11.6813L10.275 8.98515C10.7556 8.51147 10.4152 7.70156 9.73558 7.70156H4.26442C3.58476 7.70156 3.24438 8.51147 3.72498 8.98515L6.46055 11.6813Z"
                                  fill="#CBD5E0"
                                />
                              </svg>
                            )
                          ) : (
                            <></>
                          )}
                        </Box>
                      </Box>
                    </TableCell>
                  );
                })}
                <TableCell
                  sx={{
                    bgcolor: "#141414 !important",
                    color: "rgba(255, 255, 255, 0.5)",
                    "&.MuiTableCell-root": {
                      borderBottom: "1px solid rgba(255, 255, 255, 0.06)",
                    },
                  }}
                  style={{
                    width: "fit-content",
                    padding: "16px 16px 16px 16px",
                    backgroundColor: "transparent",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          width: "fit-content",
                          fontWeight: "500",
                        }}
                      >
                        Actions
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  right: 0,
                  left: 0,
                  bottom: 0,
                  top: 120,
                  height: "100%",
                }}
              >
                <CircularProgress
                  size={24}
                  sx={{
                    color: "white",
                    marginRight: "12px",
                  }}
                />
              </Box>
            ) : (
              <TableBody>
                {rows
                  ?.sort((a: any, b: any) => {
                    if (sortBy === "Employee") {
                      if (
                        columnsSortActions[sortBy?.replace(/\s+/g, "")] ===
                        "Asc"
                      ) {
                        const nameA = a?.employeeProfile?.lastName || "";
                        const nameB = b?.employeeProfile?.lastName || "";
                        return nameA.localeCompare(nameB);
                      } else {
                        const nameA = a?.employeeProfile?.lastName || "";
                        const nameB = b?.employeeProfile?.lastName || "";
                        return nameB.localeCompare(nameA);
                      }
                    } else if (sortBy === "DateEdited") {
                      const dateA = new Date(a?.createdAt);
                      const dateB = new Date(b?.createdAt);
                      // console.log("line 365",dateA,"-----",dateB)
                      if (columnsSortActions[sortBy] === "Asc") {
                        // console.log("line 367",dateA.getTime() - dateB.getTime())
                        return dateA.getTime() - dateB.getTime(); // Asc
                      } else {
                        return dateB.getTime() - dateA.getTime(); // Desc
                      }
                    }
                    return 0;
                  })
                  .map((row: any) => {
                    return (
                      <Row
                        key={row?.id}
                        row={row}
                        handleRefreshData={handleRefreshData}
                        isSelected={selectedRows?.includes(row.id)}
                        onSelect={(id, checked) => {
                          if (!id) {
                            return;
                          }
                          if (checked) {
                            setSelectedRows((prevSelected: any) => [
                              ...prevSelected,
                              id,
                            ]);
                            if (selectedRows?.length === rows?.length - 1) {
                              setSelectAll(true);
                            }
                          } else {
                            setSelectedRows((prevSelected: any) =>
                              prevSelected.filter((rowId: any) => rowId !== id)
                            );
                            if (selectedRows?.length === 1) {
                              setSelectAll(false);
                            }
                          }
                        }}
                      />
                    );
                  })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
};

export default LayoutTable;
