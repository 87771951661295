import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../../shared/components/button/Buttons";
import CrossIconSvg from "../../../assets/icons/CrossIcon/CrossIconSvg";
import { FiltersPopupNavigation } from "../../../dashboard/navigation/navigation";
import FilterContent from "./FilterContent";

type Props = {
  activeDialog: any;
  handleDialog?: any;
};

const FiltersPopup: React.FC<Props> = (props: Props) => {
  const [filtersavigations, setFiltersavigations] = useState([]);
  const [activeNavigation, setActiveNavigation] = useState<any>(null);
  useEffect(() => {
    setFiltersavigations(FiltersPopupNavigation);
  }, []);

  useEffect(() => {
    if (filtersavigations.length > 0) {
      const firstItem = filtersavigations[0];
      setActiveNavigation(firstItem);
    }
  }, [filtersavigations]);

  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            zIndex: 5000,
          }}
        >
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              border: "1px solid red",
              bgcolor: "rgba(0, 0, 0, 0.60)",
              filter: "blur(62px)",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 6000,
          }}
        >
          <Box
            sx={{
              // width: "980px",
              width: "759px",
              pb: "24px",
              pt: "24px",
              // minHeight: "776px",
              minHeight: "650px",
              height: "auto",
              bgcolor: "#1B1B1B",
              borderRadius: "14px",
              border: "1px solid #292929",
              cursor: "default",
              position: "relative",
            }}
          >
            {/* Dialog content */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {/* Title section */}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "24px",
                    lineHeight: "28px",
                    fontWeight: "600",
                    color: "#FFFFFF",
                    ml: "24px",
                  }}
                >
                  Filter
                </Typography>
                <Box
                  onClick={(e) => {
                    e.stopPropagation();
                    props?.handleDialog();
                  }}
                  sx={{
                    position: "absolute",
                    right: "24px",
                    top: "24px",
                    cursor: "pointer",
                    color: "white",
                  }}
                >
                  <CrossIconSvg />
                </Box>
              </Box>

              {/* Divider  */}
              <Box
                sx={{
                  mt: "24px",
                  width: "100%",
                  height: "1px",
                  bgcolor: "rgba(255, 255, 255, 0.06)",
                }}
              />

              <Box
                sx={{
                  display: "flex",
                }}
              >
                {/* Sidebar */}
                <Box
                  sx={{
                    width: "200px",
                    margin: "24px 25px 24px 24px",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    color: "#FFFFFF",
                  }}
                >
                  {filtersavigations
                    ?.filter((a: any) => a !== null)
                    ?.map((item: any) => (
                      <Box
                        key={item.text}
                        sx={{
                          display: "flex",
                          height:"52px",
                          alignItems: "center",
                          gap: "8px",
                          cursor: "pointer",
                          padding: "14px 65px 14px 14px",
                          borderRadius: "14px",
                          bgcolor:
                            activeNavigation?.text === item.text
                              ? "#282828"
                              : "#1B1B1B",
                          border:
                            activeNavigation?.text === item.text
                              ? "1px solid #3D3D3D"
                              : "1px solid #1B1B1B",
                        }}
                        onClick={() => {
                          setActiveNavigation(item);
                        }}
                      >
                        <Box
                          sx={{
                            width: "24px",
                            height: "24px",
                          }}
                        >
                          {activeNavigation?.text === item.text
                            ? item.iconSelected
                            : item.icon}
                        </Box>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color:
                              activeNavigation?.text === item?.text
                                ? "#FFFFFF"
                                : "rgba(255, 255, 255, 0.5)",
                          }}
                        >
                          {item.text}
                        </Typography>
                      </Box>
                    ))}
                </Box>

                {/* Filters tabs and content divider */}
                <Box
                  sx={{
                    width: "1px",
                    height: "557px",
                    bgcolor: "rgba(255, 255, 255, 0.06)",
                  }}
                />

                {/* Filters Data  */}
                <Box
                  sx={{
                    // marginX: "24px",
                    // mt: "11px",
                    width: "100%",
                    // height: "547px",
                    height: "557px",
                    overflow: "scroll",
                    scrollbarWidth: "none",
                    // border:"1px solid red"
                  }}
                >
                  <FilterContent activeNavigation={activeNavigation} />
                </Box>
              </Box>

              {/* Divider  */}
              <Box
                sx={{
                  mb: "24px",
                  width: "100%",
                  height: "1px",
                  bgcolor: "rgba(255, 255, 255, 0.06)",
                }}
              />

              {/* -------Action buttons container------- */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  width:"230px",
                  margin:"0 auto",
                }}
              >
                <ButtonSecondary
                  sx={{
                    height: "46px",
                    maxWidth:"110px",
                    width: "100%",
                    bgcolor: "transparent",
                    borderRadius: "14px",
                    padding: "15px 16px",
                  }}
                  onClick={() => {
                    props?.handleDialog();
                  }}
                  LabelStyle={{ 
                    fontSize: "18px !important",
                    fontWeight:"600",
                    lineHeight:"22px",
                  }}
                  label="Clear All"
                />
                <ButtonPrimary
                  sx={{
                    height: "46px !important",
                    borderRadius: "14px !important",
                    width: "100%",
                    maxWidth:"110px",
                    padding: "15px 32px",
                  }}
                  onClick={() => {
                    props?.handleDialog();
                  }}
                  LabelStyle={{ 
                    fontSize: "18px !important",
                    fontWeight:"600",
                    lineHeight:"22px",
                  }}
                  label="Save"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  );
};

export default FiltersPopup;
