import { Box, Button, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import { ImageContainer } from "../../../../shared/components/ImageContainer";
import { EditIcon, TrashIcon } from "../../../../assets/icons/icons";
import DeleteDialog from "../../../products/product/DeleteDialog";
import { deleteCategory } from "../../../../services/category";
import { toastError, toastSuccess } from "../../../../utils/ToastMessage";
import { useGlobalContext } from "../../../../App";
import { useNavigate } from "react-router-dom";

interface tableRowProps {
  row: any;
  handleRefreshData?: any;
  isSelected?: any;
  onSelect?: (id: any, checked: boolean) => any;
}

const Row: React.FC<tableRowProps> = ({ row, handleRefreshData }) => {
  const navigate = useNavigate();
  const { setAddCategoryPopup ,setIsEditCategoryState} = useGlobalContext();
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

  const handleDeleteCategory = async () => {
    try {
      await deleteCategory(row?._id).then((res: any) => {
        toastSuccess("Successfully Deleted!", "Category deleted successfully.");
        handleRefreshData();
      });
    } catch (error) {
      toastError("Ops! Something went wrong.", "Failed deleting the category.");
      console.error("Error deleting category:", error);
    } finally {
      setIsDeleteDialogVisible(false);
    }
  };
  
  const handleUpdateCategory = ()=>{
    setIsEditCategoryState(true)
    setAddCategoryPopup(true)
  }

  return (
    <TableRow
      onClick={() => {}}
      sx={{
        cursor: "pointer",
        ":hover": {
          bgcolor: "rgba(255, 255, 255, 0.06) !important",
        },
        width: "100%",
        height: "66px",
        display: "flex",
        justifyContent: "space-between",
        paddingRight: "14px",
        borderBottom: "1px solid rgba(255, 255, 255, 0.06)",
      }}
      hover
      role="checkbox"
      tabIndex={-1}
      key={row?._id}
    >
      <TableCell
        sx={{
          minWidth: "146px",
          borderBottom: "none",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            color: "#FFFFFF",
          }}
        >
          {row?.value || "-"}
        </Box>
      </TableCell>

      <TableCell
        sx={{
          minWidth: "125px",
          borderBottom: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "72px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "24px",
          }}
        >
          <Button
            onClick={() => {
              handleUpdateCategory()
              navigate(`/settings/categories`, {
                state: {
                  categoryData: row,
                },
              });
            }}
            sx={{
              minWidth: "0px",
              minHeight: "0px",
              lineHeight: "0px",
              ":hover": {
                bgcolor: "none !important",
              },
            }}
          >
            <ImageContainer
              style={{ width: "24px", height: "24px" }}
              title={"edit"}
            >
              {EditIcon}
            </ImageContainer>
          </Button>

          <Button
            onClick={() => {
              setIsDeleteDialogVisible(true);
            }}
            sx={{
              minWidth: "0px",
              minHeight: "0px",
              lineHeight: "0px",
            }}
          >
            <ImageContainer
              style={{ width: "24px", height: "24px" }}
              title="trash"
            >
              {TrashIcon}
            </ImageContainer>
          </Button>
        </Box>

        {isDeleteDialogVisible && (
          <DeleteDialog
            title="Are you sure you want to delete the Category ?"
            setIsDeleteDialogVisible={setIsDeleteDialogVisible}
            deleteAction={() => {
              handleDeleteCategory();
            }}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;
