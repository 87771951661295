import axios from "axios";
import Cookies from "js-cookie";
import { HOST_NAME } from "../utils/constants";

export const createComponent = async (data: any, files: File[]) => {
  return new Promise((resolve, reject) => {
    // Configure the request
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    // Send the request
    axios
      .request(config)
      .then((response) => {
        files.forEach(async (file, index) => {
          await uploadFileToS3(
            file,
            { componentId: response?.data?.data?._id },
            "component"
          );
        });
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadFileToS3 = (
  file: File,
  metadata: any,
  folderName: string
) => {
  return new Promise((resolve, reject) => {
    // Step 1: Get pre-signed URL
    const configPresignedUrl = {
      method: "post",
      url: `${HOST_NAME}/generate-presigned-url`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
        "Content-Type": "application/json",
      },
      data: {
        fileName: file.name,
        fileType: file.type,
        metadata,
        folderName,
      },
    };

    // Step 2: Notify backend to create document entry
    axios
      .request(configPresignedUrl)
      .then(async (response) => {
        const { signedUrl, key } = response?.data?.data;

        const configCreateDocument = {
          method: "post",
          url: `${HOST_NAME}/create-document-entry`,
          headers: {
            Authorization: `Bearer ${Cookies.get("jstoken")}`,
            "Content-Type": "application/json",
          },
          data: {
            key,
            url: signedUrl.split("?")[0], // Extract the URL
            metadata,
            fileSize: file.size,
            fileType: file.type,
          },
        };

        //Create the document entry
        axios.request(configCreateDocument);

        // Step 3: Upload file to S3
        const configUploadToS3 = {
          method: "put",
          url: signedUrl,
          headers: {
            "Content-Type": file.type,
          },
          data: file,
        };

        const res = await axios
          .request(configUploadToS3)
          .then(() => ({ signedUrl, key }));

        return res;
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        reject(error);
      });
  });
};

export const uploadDocument = async (file: any) => {
  let formData = new FormData();
  formData.append("files", file);
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/documents/upload`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    const response = await axios.request(config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const getAllComponents = (filters?: any) => {
  let status: string = "";
  if (filters?.status) {
    status = filters?.status;
  }

  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component?search=${
        filters?.searchBy || ""
      }&searchByTitle=${filters?.searchByTitle || ""}&page=${
        filters?.page || 1
      }&pageSize=${filters?.pageSize || 25}&status=${status}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getComponentById = (id: string) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component/${id}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getComponentSourceCodeByComponentId = (componentId: string) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component-source/${componentId}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteComponent = (id: string) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component/${id}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteDocuments = (data: any) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/documents`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
      data: { documentIds: data },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateComponent = (id: string, data: any, files?: any) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component/${id}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        files.forEach(async (file: any) => {
          await uploadFileToS3(
            file,
            { componentId: response?.data?.data?._id },
            "component"
          );
        });
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateMultipleComponents = (data: any) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/multiple-component`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllComponentsFeedback = (filters?: any) => {
  return new Promise<any>((resolve, reject) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/feedbacked-components?search=${
        filters?.searchBy || ""
      }&page=${filters?.page || 1}&pageSize=${filters?.pageSize || 25}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const getAllFeedbacks = (filters?: any) => {
  return new Promise((resolve, reject) => {
    const page =
      filters?.page !== undefined &&
      filters?.page !== null &&
      filters?.page !== ""
        ? filters?.page
        : "";
    const pageSize =
      filters?.pageSize !== undefined &&
      filters?.pageSize !== null &&
      filters?.pageSize !== ""
        ? filters?.pageSize
        : "";
    const componentId =
      filters?.componentId !== undefined &&
      filters?.componentId !== null &&
      filters?.componentId !== ""
        ? filters?.componentId
        : "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/feedback?componentId=${componentId}&page=${page}&pageSize=${pageSize}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateFeedbackById = (data: any, id: string) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/feedback/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const getAllComponentsReport = (filters?: any) => {
  return new Promise<any>((resolve, reject) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/reported-components?search=${
        filters?.searchBy || ""
      }&page=${filters?.page || 1}&pageSize=${filters?.pageSize || 25}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const getAllReports = (filters?: any) => {
  return new Promise((resolve, reject) => {
    const page =
      filters?.page !== undefined &&
      filters?.page !== null &&
      filters?.page !== ""
        ? filters?.page
        : "";
    const pageSize =
      filters?.pageSize !== undefined &&
      filters?.pageSize !== null &&
      filters?.pageSize !== ""
        ? filters?.pageSize
        : "";
    const componentId =
      filters?.componentId !== undefined &&
      filters?.componentId !== null &&
      filters?.componentId !== ""
        ? filters?.componentId
        : "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/problem?componentId=${componentId}&page=${page}&pageSize=${pageSize}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateReportById = (data: any, id: string) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/problem/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const getAllComponentsCollections = (filters?: any) => {
  return new Promise<any>((resolve, reject) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component-collection?search=${
        filters?.searchBy || ""
      }&page=${filters?.page || 1}&pageSize=${
        filters?.pageSize || 25
      }&includeComponents=${filters?.includeComponents}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const createComponentCollection = (data: any) => {
  return new Promise<any>((resolve, reject) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component-collection`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const deleteComponentCollection = (id: string) => {
  return new Promise<void>((resolve, reject) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component-collection/${id}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const updateComponentCollection = (id: any, data: any) => {
  return new Promise<any>((resolve, reject) => {
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component-collection/${id}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
