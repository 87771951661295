import React from "react";
import { IconColor } from "../interface/ColorInterface";

const CategoryIconSvg:React.FC<IconColor> = ({
  color,
  width,
  height,
  transform,
}) => {
  return (
    <svg
    width={width || "24"}
    height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.24725 3C4.01363 3 3 4.01363 3 5.24725V8.99609C3 10.2297 4.01363 11.2507 5.24725 11.2507H9.00195C10.2356 11.2507 11.2492 10.2297 11.2492 8.99609V5.24725C11.2492 4.01363 10.2356 3 9.00195 3H5.24725ZM14.998 3C13.7644 3 12.7508 4.01363 12.7508 5.24725V8.99609C12.7508 10.2297 13.7644 11.2507 14.998 11.2507H18.7527C19.9864 11.2507 21 10.2297 21 8.99609V5.24725C21 4.01363 19.9864 3 18.7527 3H14.998ZM5.24725 4.50012H9.00195C9.43046 4.50012 9.74908 4.81875 9.74908 5.24725V8.99609C9.74908 9.4246 9.43046 9.74909 9.00195 9.74909H5.24725C4.81875 9.74909 4.50012 9.4246 4.50012 8.99609V5.24725C4.50012 4.81875 4.81875 4.50012 5.24725 4.50012ZM14.998 4.50012H18.7527C19.1813 4.50012 19.4999 4.81875 19.4999 5.24725V8.99609C19.4999 9.4246 19.1813 9.74909 18.7527 9.74909H14.998C14.5695 9.74909 14.2509 9.4246 14.2509 8.99609V5.24725C14.2509 4.81875 14.5695 4.50012 14.998 4.50012ZM5.24725 12.7508C4.01363 12.7508 3 13.7644 3 14.998V18.7527C3 19.9864 4.01363 21 5.24725 21H9.00195C10.2356 21 11.2492 19.9864 11.2492 18.7527V14.998C11.2492 13.7644 10.2356 12.7508 9.00195 12.7508H5.24725ZM14.998 12.7508C13.7644 12.7508 12.7508 13.7644 12.7508 14.998V18.7527C12.7508 19.9864 13.7644 21 14.998 21H18.7527C19.9864 21 21 19.9864 21 18.7527V14.998C21 13.7644 19.9864 12.7508 18.7527 12.7508H14.998ZM5.24725 14.2509H9.00195C9.43046 14.2509 9.74908 14.5695 9.74908 14.998V18.7527C9.74908 19.1813 9.43046 19.4999 9.00195 19.4999H5.24725C4.81875 19.4999 4.50012 19.1813 4.50012 18.7527V14.998C4.50012 14.5695 4.81875 14.2509 5.24725 14.2509ZM14.998 14.2509H18.7527C19.1813 14.2509 19.4999 14.5695 19.4999 14.998V18.7527C19.4999 19.1813 19.1813 19.4999 18.7527 19.4999H14.998C14.5695 19.4999 14.2509 19.1813 14.2509 18.7527V14.998C14.2509 14.5695 14.5695 14.2509 14.998 14.2509Z"
        fill={color || "white"}
      />
    </svg>
  );
};

export default CategoryIconSvg;
