import { Box, Button, TableCell, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";
import { ImageContainer } from "../../../shared/components/ImageContainer";
import { EditIcon, TrashIcon } from "../../../assets/icons/icons";
import { useNavigate } from "react-router-dom";
import DeleteDialog from "../../products/product/DeleteDialog";
import { statusType } from "../../../utils/constants";
import SkylineIconSvg from "../../../assets/icons/SkylineIcon/SkylineIconSvg";
import { useGlobalContext } from "../../../App";
import { deleteComponentCollection } from "../../../services/component";
import { toastError, toastSuccess } from "../../../utils/ToastMessage";

interface tableRowProps {
  row: any;
  handleRefreshData?: any;
  isSelected?: any;
  onSelect?: (id: any, checked: boolean) => any;
}

const Row: React.FC<tableRowProps> = ({ row, handleRefreshData }) => {
  // const { setAddCollectionUI } = useGlobalContext();
  const navigate = useNavigate();
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  // console.log("row", row)
  const handleDelete = async () => {
    try {
      await deleteComponentCollection(row?._id).then((res: any) => {
        toastSuccess(
          "Successfully Deleted!",
          "Component deleted successfully."
        );
        handleRefreshData();
      });
    } catch (error) {
      toastError(
        "Ops! Something went wrong.",
        "Failed deleting the component."
      );
      console.error("Error deleting collection:", error);
    } finally {
      setIsDeleteDialogVisible(false);
    }
  };
  return (
    <TableRow
      onClick={() => {}}
      sx={{
        cursor: "pointer",
        ":hover": {
          bgcolor: "rgba(255, 255, 255, 0.06) !important",
        },
        display: "flex",
        gap: "36px",
        paddingRight: "80px",
        height:"100px",
        borderBottom: "1px solid rgba(255, 255, 255, 0.06)",
        // justifyContent: "center",
        // alignItems: "center",
      }}
      hover
      role="checkbox"
      tabIndex={-1}
      key={row?.id}
    >
      <TableCell
        sx={{
          borderBottom: "none",
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {row?.logoUrl ? (
            <img
              src={row.logoUrl}
              alt={row.name}
              style={{
                width: "100%",
                // width: "28px",
                height: "auto",
                maxHeight: "40px",
                objectFit: "contain",
                // border:"1px solid red",
              }}
            />
          ) : (
            <SkylineIconSvg />
          )}
        </Box>
      </TableCell>

      <TableCell
        sx={{ borderBottom: "none", flex: 1 , display:"flex",alignItems: "center",}}
      >
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{ color: "#FFFFFF", fontWeight: "300" }}
            variant="body2"
          >
            {row?.name || "N/A"}
          </Typography>
        </Box>
      </TableCell>

      <TableCell
        sx={{
          flex: 1,
          borderBottom: "none",
          display: "flex",
          alignItems:"center",
        }}
      >
        <Typography sx={{ color: "white", fontWeight: "300" }} variant="body2">
          {/* {Math.floor(Math.random() * 10) + 1} */}
          {row?.components?.length}
        </Typography>
      </TableCell>

      <TableCell
        sx={{
          flex: 1,
          borderBottom: "none",
          display: "flex",
          alignItems:"center",
        }}
      >
        <Typography sx={{ color: "white", fontWeight: "300" }} variant="body2">
          {row?.status ? statusType(row?.status) : "-"}
        </Typography>
      </TableCell>

      <TableCell
        sx={{
          flex: 1,
          borderBottom: "none",
          display: "flex",
          alignItems:"center",
        }}
      >
        <Box
          sx={{
            width: "72px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "24px",
          }}
        >
          <Button
            onClick={() => {
              navigate(`/collection/edit/${row?._id}`, {
                state: {
                  collectionData: row,
                },
              });
            }}
            sx={{
              minWidth: "0px",
              minHeight: "0px",
              lineHeight: "0px",
              ":hover": {
                bgcolor: "none !important",
              },
            }}
          >
            <ImageContainer
              style={{ width: "24px", height: "24px" }}
              title={"edit"}
            >
              {EditIcon}
            </ImageContainer>
          </Button>

          <Button
            onClick={() => {
              setIsDeleteDialogVisible(true);
            }}
            sx={{
              minWidth: "0px",
              minHeight: "0px",
              lineHeight: "0px",
            }}
          >
            <ImageContainer
              style={{ width: "24px", height: "24px" }}
              title="trash"
            >
              {TrashIcon}
            </ImageContainer>
          </Button>
        </Box>

        {isDeleteDialogVisible && (
          <DeleteDialog
            title="Are you sure you want to delete the collection ?"
            setIsDeleteDialogVisible={setIsDeleteDialogVisible}
            deleteAction={() => {
              handleDelete();
            }}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default Row;
